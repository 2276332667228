import { ArrowLeftOutlined, CaretDownOutlined, SettingOutlined, SyncOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Divider, Dropdown, Menu, Tooltip } from 'antd';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import configuration from '../../configs/IConfiguration';
import CustomContext from '../../context/CustomContext';
import { ApplicationType, PageType } from '../../model/types';
import rolesService from '../../services/RolesService';
import styles from './MenuComponent.module.scss';

const MenuComponent = (props: Props): React.ReactElement => {
    const { pageId } = props;

    /*** HOOKS ***/

    const context = useContext(CustomContext);
    const { auth, application } = context;
    const history = useHistory();

    let selectedKeys = pageId ? [pageId] : [];
    const tooltipAlign = { offset: [0, -19] };

    if (auth && !application && window.location.pathname === '/tasks') {
        return (
            <div className={styles.subheader}>
                <NavigationDropdownComponent application={application} />
            </div>
        );
    } else if (
        auth &&
        !application &&
        window.location.pathname !== '/' &&
        !window.location.pathname.startsWith('/autodesk') &&
        !window.location.pathname.startsWith('/login-error')
    ) {
        return (
            <div className={styles.subheader}>
                <Button icon={<ArrowLeftOutlined />} type="text" onClick={() => history.goBack()}></Button>
            </div>
        );
    } else if (auth && !application && window.location.pathname.startsWith('/login-error')) {
        return (
            <a className={styles.subheader} href={`${configuration.getParentUrl()}`}>
                <Button icon={<ArrowLeftOutlined />} type="text"></Button>
            </a>
        );
    } else if (auth && application === 'SYNC') {
        return (
            <div className={styles.subheader}>
                <NavigationDropdownComponent application={application} />
                <Menu
                    theme="dark"
                    forceSubMenuRender
                    mode="horizontal"
                    defaultSelectedKeys={['connections']}
                    selectedKeys={selectedKeys}
                    className={styles.menu}
                >
                    <Menu.Item key="connections">
                        <Tooltip
                            title={<FormattedMessage id="header.apps.sync.connections.tooltip" />}
                            align={tooltipAlign}
                        >
                            <Link to="/sync/connections">
                                <FormattedMessage id="header.apps.sync.connections" />
                            </Link>
                        </Tooltip>
                    </Menu.Item>
                    <Menu.Item key="files">
                        <Tooltip title={<FormattedMessage id="header.apps.sync.files.tooltip" />} align={tooltipAlign}>
                            <Link to="/sync/files">
                                <FormattedMessage id="header.apps.sync.files" />
                            </Link>
                        </Tooltip>
                    </Menu.Item>
                </Menu>
            </div>
        );
    } else if (auth && application === 'SETUP') {
        const isMembersVisible = auth.members.some((m) => m.bim360 === 'ADMIN');

        return (
            <div className={styles.subheader}>
                <NavigationDropdownComponent application={application} />
                <Menu
                    theme="dark"
                    mode="horizontal"
                    defaultSelectedKeys={['projectMembers']}
                    selectedKeys={selectedKeys}
                    forceSubMenuRender
                    className={styles.menu}
                >
                    <Menu.Item key="projectMembers">
                        <Link to="/setup/project-members">
                            <FormattedMessage id="header.apps.setup.projectMembers" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="projectIssues" hidden={!rolesService.hasAnyRole(auth, ['ROLE_ADMIN', 'ROLE_USER'])}>
                        <Tooltip
                            title={<FormattedMessage id="header.apps.setup.projectIssues.tooltip" />}
                            align={tooltipAlign}
                        >
                            <Link to="/setup/project-issues">
                                <FormattedMessage id="header.apps.setup.projectIssues" />
                            </Link>
                        </Tooltip>
                    </Menu.Item>
                    <Menu.Item key="projects" hidden={!rolesService.hasAnyRole(auth, ['ROLE_ADMIN'])}>
                        <Tooltip
                            title={<FormattedMessage id="header.apps.setup.projects.tooltip" />}
                            align={tooltipAlign}
                        >
                            <Link to="/setup/projects">
                                <FormattedMessage id="header.apps.setup.projects" />
                            </Link>
                        </Tooltip>
                    </Menu.Item>
                    {/*
    <Menu.Item key="projectSubmissions">
        <Tooltip
            title={<FormattedMessage id="header.apps.setup.projectSubmissions.tooltip" />}
            align={tooltipAlign}
        >
            <Link to="/setup/project-submissions">
                <FormattedMessage id="header.apps.setup.projectSubmissions" />
            </Link>
        </Tooltip>
    </Menu.Item>
    */}
                    {isMembersVisible && (
                        <Menu.Item key="members">
                            <Tooltip
                                title={<FormattedMessage id="header.apps.setup.members.tooltip" />}
                                align={tooltipAlign}
                            >
                                <Link to="/setup/members">
                                    <FormattedMessage id="header.apps.setup.members" />
                                </Link>
                            </Tooltip>
                        </Menu.Item>
                    )}
                </Menu>
            </div>
        );
    } else {
        return <></>;
    }
};

export default MenuComponent;

interface Props {
    pageId?: PageType;
}

const NavigationDropdownComponent = (props: DropdownProps): React.ReactElement => {
    const { application } = props;

    const items: MenuProps['items'] = [
        {
            key: 'tasks',
            label: (
                <Link to="/tasks">
                    <div className={styles.dropdownItem}>
                        <FormattedMessage id="header.apps.tasks" />
                    </div>
                </Link>
            ),
        },
        { type: 'divider' },
        {
            key: 'setup',
            label: (
                <Link to="/setup">
                    <div className={styles.dropdownItem}>
                        <FormattedMessage id="header.apps.setup" />
                    </div>
                </Link>
            ),
            icon: <SettingOutlined className={styles.icon} />,
        },
        {
            key: 'sync',
            label: (
                <Link to="/sync">
                    <div className={styles.dropdownItem}>
                        <FormattedMessage id="header.apps.sync" />
                    </div>
                </Link>
            ),
            icon: <SyncOutlined className={styles.icon} />,
        },
    ];

    /** COMPONENTS */

    return (
        <>
            <Dropdown menu={{ items }} trigger={['click']}>
                <div className={styles.dropdown}>
                    <div className={styles.dropdownText}>
                        {application ? (
                            application
                        ) : (
                            <div className={styles.toUppercase}>
                                <FormattedMessage id="header.apps.tasks" />
                            </div>
                        )}
                    </div>
                    <CaretDownOutlined />
                </div>
            </Dropdown>
            <Divider type="vertical" className={styles.divider} />
        </>
    );
};

interface DropdownProps {
    application?: ApplicationType;
}
