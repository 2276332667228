import { IConfiguration, Platform, Tenant } from './IConfiguration';

export default class ModelicalConfiguration implements IConfiguration {
    // domains of environments (hardcoded - react doesn't allow changes on runtime)
    readonly modelicalDevDomain = 'dev.modelical.io';
    readonly modelicalProductionDomain = 'modelical.io';

    // configuration attributes
    auth: any | undefined;
    parentUrl: string | undefined;
    adminMicroserviceUrl: string;
    proxyMicroserviceUrl: string;
    syncMicroserviceUrl: string;
    mioAdminUrl: string;
    autodeskClientId: string;
    parentDomain: string;
    cookiesSubfix: string;

    constructor() {
        // set up configuration attributes based on environment
        if (window.location.hostname.endsWith('link.' + this.modelicalDevDomain)) {
            this.auth = {
                auth: {
                    clientId: '7819f092-5634-499f-90c0-cf5ff111f1c3',
                    authority: 'https://login.microsoftonline.com/d8c21a0f-8e79-48d2-9314-075f557b317d',
                    redirectUri: `https://link.${this.modelicalDevDomain}`,
                    postLogoutRedirectUri: `https://link.${this.modelicalDevDomain}`,
                    navigateToLoginRequestUrl: true,
                    OIDCOptions: {
                        defaultScopes: ['7819f092-5634-499f-90c0-cf5ff111f1c3/.default'],
                    },
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: false,
                },
            };

            this.parentUrl = `https://dev.modelical.io`;
            this.adminMicroserviceUrl = `https://admin.link.${this.modelicalDevDomain}`;
            this.proxyMicroserviceUrl = `https://proxy.link.${this.modelicalDevDomain}`;
            this.syncMicroserviceUrl = `https://sync.link.${this.modelicalDevDomain}`;
            this.mioAdminUrl = `https://admin.api.${this.modelicalDevDomain}`;
            this.autodeskClientId = 'sD2OjZVhURwjaUdgnGHf4oUuG7FBq2zVv06UXNxonrn56J2k';
            this.parentDomain = 'dev.modelical.io';
            this.cookiesSubfix = 'DevModelicalIo';
        } else {
            this.auth = {
                auth: {
                    clientId: '45537c53-5834-4b2f-80b6-265c836fe965',
                    authority: 'https://login.microsoftonline.com/d8c21a0f-8e79-48d2-9314-075f557b317d',
                    redirectUri: `https://link.${this.modelicalProductionDomain}`,
                    postLogoutRedirectUri: `https://link.${this.modelicalProductionDomain}`,
                    navigateToLoginRequestUrl: true,
                    OIDCOptions: {
                        defaultScopes: ['45537c53-5834-4b2f-80b6-265c836fe965/.default'],
                    },
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: false,
                },
            };

            this.parentUrl = `https://modelical.io`;
            this.adminMicroserviceUrl = `https://admin.link.${this.modelicalProductionDomain}`;
            this.proxyMicroserviceUrl = `https://proxy.link.${this.modelicalProductionDomain}`;
            this.syncMicroserviceUrl = `https://sync.link.${this.modelicalProductionDomain}`;
            this.mioAdminUrl = `https://admin.api.${this.modelicalProductionDomain}`;
            this.autodeskClientId = 'MMWqb4AgngZOSFegAHV8Wu8j2kFQY6QiwoePoIX6yTDZHDkG';
            this.parentDomain = 'modelical.io';
            this.cookiesSubfix = 'ModelicalIo';
        }
    }

    getPlatform = (): Platform => {
        return 'azure';
    };

    getAuth = (): any | undefined => {
        return this.auth;
    };

    getParentUrl = (): string | undefined => {
        return this.parentUrl;
    };

    getAdminMicroserviceUrl = (): string => {
        return this.adminMicroserviceUrl;
    };

    getProxyMicroserviceUrl = (): string => {
        return this.proxyMicroserviceUrl;
    };

    getSyncMicroserviceUrl = (): string => {
        return this.syncMicroserviceUrl;
    };

    getMioAdminUrl = (): string => {
        return this.mioAdminUrl;
    };

    getAutodeskClientId = (): string => {
        return this.autodeskClientId;
    };

    getTenant = (): Tenant => {
        return 'modelical';
    };

    getParentDomain = (): string => {
        return this.parentDomain;
    };

    getCookiesSubfix = (): string => {
        return this.cookiesSubfix;
    };
}
