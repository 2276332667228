import { Principal, User } from '../model/entities';
import { basicAdminApiAxios } from './CustomAxios';

class UserApi {
    getPrincipal = async (): Promise<Principal> => {
        const response = await basicAdminApiAxios.get<Principal>('/users/current');

        return response.data;
    };

    createSystemUserBim360AccessToken = async (accessToken: string, refreshToken: string): Promise<User> => {
        const response = await basicAdminApiAxios.post<User>('/users/system-user/bim360-access-token', {
            accessToken,
            refreshToken,
        });
        return response.data;
    };
}

const userApi: UserApi = new UserApi();
export default userApi;
