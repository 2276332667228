import FileSaver from 'file-saver';
import qs from 'qs';
import { Page } from '../model/elements';
import { EntityWithStatus, ProjectMember, ProjectMemberImport, TaskMember } from '../model/entities';
import { ProviderType } from '../model/types';
import { proxyApiAxios } from './CustomAxios';

class ProjectMemberApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        grouped: boolean,
        hubId: string,
        projectId: string[],
        searchText?: string,
        platform?: 'ACC' | 'BIM360',
    ): Promise<Page<ProjectMember>> => {
        const response = await proxyApiAxios.get<Page<ProjectMember>>('/project-members', {
            params: {
                page,
                size,
                sortField,
                sortOrder,
                grouped,
                hubId,
                projectId,
                searchText,
                platform,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;

        return response.data;
    };

    addMember = async (
        projectMember: ProjectMember,
        hubId: string,
        projectId: string,
        providerType: ProviderType,
    ): Promise<ProjectMember> => {
        const response = await proxyApiAxios.post('/project-members', projectMember, {
            params: { projectId, providerType, hubId },
        });

        return response.data;
    };

    updateCompany = async (
        projectMembers: ProjectMember[],
        hubId: string,
    ): Promise<EntityWithStatus<ProjectMember>[]> => {
        const response = await proxyApiAxios.patch<EntityWithStatus<ProjectMember>[]>(
            `/project-members`,
            projectMembers,
            {
                params: { hubId, company: '' },
            },
        );
        return response.data;
    };

    updateRoles = async (
        projectMembers: ProjectMember[],
        hubId: string,
    ): Promise<EntityWithStatus<ProjectMember>[]> => {
        const response = await proxyApiAxios.patch<EntityWithStatus<ProjectMember>[]>(
            `/project-members`,
            projectMembers,
            {
                params: { hubId, roles: '' },
            },
        );
        return response.data;
    };

    exportSpreadSheet = async (
        sortField: string,
        sortOrder: boolean,
        hubId: string,
        projectId: string[],
        grouped: boolean,
        searchText?: string,
        platform?: 'ACC' | 'BIM360',
    ) => {
        const response = await proxyApiAxios.get<Blob>(`/project-members/spreadsheets`, {
            responseType: 'blob',
            params: { sortField, sortOrder, hubId, projectId, grouped, searchText, platform },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });
        FileSaver.saveAs(response.data, 'link-project-members.xlsx');
    };

    import = async (
        projectMembers: ProjectMember[],
        hubId: string,
        platform?: 'ACC' | 'BIM360',
    ): Promise<TaskMember> => {
        const response = await proxyApiAxios.post<TaskMember>('/project-members/imports', projectMembers, {
            params: { hubId, platform },
        });

        return response.data;
    };

    delete = async (projectMembers: ProjectMember[], hubId: string): Promise<EntityWithStatus<ProjectMember>[]> => {
        const response = await proxyApiAxios.delete<EntityWithStatus<ProjectMember>[]>('/project-members/bulk', {
            params: { hubId },
            data: projectMembers,
        });

        return response.data;
    };

    loadCsv = async (csv: string, projectMemberImport: ProjectMemberImport): Promise<ProjectMember[]> => {
        const formData = this.buildFormData(projectMemberImport, undefined, csv);
        const response = await proxyApiAxios.post<ProjectMember[]>('/project-members/csvs', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    };

    loadSpreadsheet = async (file: any, projectMemberImport: ProjectMemberImport): Promise<ProjectMember[]> => {
        const formData = this.buildFormData(projectMemberImport, file);
        const response = await proxyApiAxios.post<any>('/project-members/spreadsheets', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    };

    /**
     * Builds a form data from a project member import object.
     * @param projectMemberImport the project member import object
     * @param file the file
     * @param csv the csv
     * @returns the formdata
     */
    private buildFormData = (projectMemberImport: ProjectMemberImport, file?: File, csv?: string) => {
        const formData: FormData = new FormData();
        const projectMemberImportJson = JSON.stringify(projectMemberImport);
        const projectMemberImportBlob = new Blob([projectMemberImportJson], { type: 'application/json' });
        formData.append('projectMemberImport', projectMemberImportBlob);
        if (file) {
            formData.append('attachment', file);
        }
        if (csv) {
            formData.append('csv', csv);
        }

        return formData;
    };
}

const projectMemberApi: ProjectMemberApi = new ProjectMemberApi();
export default projectMemberApi;
