import { CacheLookupPolicy, InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import configuration from '../configs/IConfiguration';
import mioAdminSettingService from './MioAdminSettingService';

class SharePointService {
    azureEntraIdClient: PublicClientApplication | undefined;
    scopes: string[] = ['Sites.Read.All', 'Files.Read.All'];

    init = async () => {
        try {
            // if we have a sharepoint setting we initialize the client
            const sharepointSetting = mioAdminSettingService.getSharepointSetting();
            if (sharepointSetting) {
                const auth = configuration.getAuth()?.auth;
                this.azureEntraIdClient = new PublicClientApplication({
                    auth: {
                        clientId: sharepointSetting.clientId, //'196beab1-b4a2-427d-b792-26e730846659'
                        authority: `https://login.microsoftonline.com/${sharepointSetting.tenantId}`, // d8c21a0f-8e79-48d2-9314-075f557b317d
                        navigateToLoginRequestUrl: false,
                        redirectUri: auth ? `${auth.redirectUri}/blank.html` : undefined,
                        postLogoutRedirectUri: auth ? auth.postLogoutRedirectUri : undefined,
                        OIDCOptions: {
                            defaultScopes: this.scopes,
                        },
                    },
                    cache: {
                        cacheLocation: 'localStorage',
                        storeAuthStateInCookie: false,
                    },
                });
                await this.azureEntraIdClient.initialize();
            }
        } catch (error) {
            console.log(error);
        }
    };

    signIn = async () => {
        try {
            const response = await this.azureEntraIdClient!.loginPopup();
            this.azureEntraIdClient!.setActiveAccount(response.account);
        } catch (error) {
            console.log(error);
        }
    };

    signOut = async () => {
        try {
            const logoutRequest = {
                account: this.azureEntraIdClient!.getActiveAccount(),
            };
            await this.azureEntraIdClient!.logoutPopup(logoutRequest);
        } catch (error) {
            console.log(error);
        }
    };

    /**
     * Returns the access token from the cache if the user is authenticated. It doesn't attempt to renew access or refresh tokens
     * @returns the access token
     */
    getAccessTokenFromCache = async (): Promise<string | undefined> => {
        let accessToken: string | undefined;

        try {
            if (this.azureEntraIdClient) {
                await this.azureEntraIdClient.initialize();
                const account = this.azureEntraIdClient.getActiveAccount();
                if (account) {
                    const accessTokenRequest = {
                        scopes: this.scopes,
                        account: account,
                        cacheLookupPolicy: CacheLookupPolicy.AccessToken,
                    };
                    const authenticationResult = await this.azureEntraIdClient.acquireTokenSilent(accessTokenRequest);
                    accessToken = authenticationResult.accessToken;
                }
            }
        } catch (error) {
            console.log(error);
        }

        return accessToken;
    };

    /**
     * Returns the access token. It  will attempt to retrieve an access token from the cache. If the access token is expired or
     * cannot be found the refresh token will be used to acquire a new one. Finally, if the refresh token is expired, acquireTokenSilent
     * will attempt to silently acquire a new access token, id token, and refresh token.
     * @returns the access token
     */
    getAccessToken = async (): Promise<string | undefined> => {
        let accessToken: string | undefined;

        try {
            if (this.azureEntraIdClient) {
                await this.azureEntraIdClient.initialize();
                accessToken = await this.getAccessTokenFromCache();
                if (!accessToken) {
                    accessToken = await this.refreshAcessToken();
                }
            }
        } catch (error) {
            console.log(error);
        }

        return accessToken;
    };

    /**
     * Refreshes the access token. It  will attempt to retrieve an access token from the cache. If the access token is expired or
     * cannot be found the refresh token will be used to acquire a new one. Finally, if the refresh token is expired, acquireTokenSilent
     * will attempt to silently acquire a new access token, id token, and refresh token.
     * @returns the access token
     */
    private refreshAcessToken = async (): Promise<string | undefined> => {
        let accessToken: string | undefined;
        const account = this.azureEntraIdClient ? this.azureEntraIdClient.getActiveAccount() : undefined;
        if (this.azureEntraIdClient && account) {
            const auth = configuration.getAuth()?.auth;
            const accessTokenRequest = {
                scopes: this.scopes,
                account: account,
                redirectUri: auth ? `${auth.redirectUri}/blank.html` : undefined,
            };

            // get access token from the browser session or retrieve new one
            try {
                const authenticationResult = await this.azureEntraIdClient.acquireTokenSilent(accessTokenRequest);
                accessToken = authenticationResult.accessToken;
            } catch (errorSilentRefresh) {
                if (errorSilentRefresh instanceof InteractionRequiredAuthError) {
                    try {
                        const response = await this.azureEntraIdClient.acquireTokenPopup(accessTokenRequest);
                        accessToken = response.accessToken;
                    } catch (errorTokenPopup) {
                        console.log(errorTokenPopup);
                        this.azureEntraIdClient.clearCache({
                            account: account,
                        });
                    }
                } else {
                    console.log(errorSilentRefresh);
                    this.azureEntraIdClient.clearCache({
                        account: account,
                    });
                }
            }
        }

        return accessToken;
    };
}
const sharePointService: SharePointService = new SharePointService();
export default sharePointService;
