import { IConfiguration, Platform, Tenant } from './IConfiguration';

export default class CellnexConfiguration implements IConfiguration {
    // domains of environments (hardcoded - react doesn't allow changes on runtime)
    readonly irisTestDomain = 'iris.modelical.io';

    // configuration attributes
    auth: any | undefined;
    parentUrl: string | undefined;
    adminMicroserviceUrl: string;
    proxyMicroserviceUrl: string;
    syncMicroserviceUrl: string;
    mioAdminUrl: string;
    autodeskClientId: string;
    parentDomain: string;
    cookiesSubfix: string;

    constructor() {
        // set up configuration attributes based on environment
        this.auth = {
            auth: {
                clientId: 'eed14693-0040-4f6d-89bc-22b436918ef6',
                authority: 'https://login.microsoftonline.com/d8c21a0f-8e79-48d2-9314-075f557b317d',
                redirectUri: `https://link.${this.irisTestDomain}`,
                postLogoutRedirectUri: `https://link.${this.irisTestDomain}`,
                navigateToLoginRequestUrl: true,
                OIDCOptions: {
                    defaultScopes: ['eed14693-0040-4f6d-89bc-22b436918ef6/.default'],
                },
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false,
            },
        };

        this.parentUrl = `https://iris.modelical.io`;
        this.adminMicroserviceUrl = `https://admin.link.${this.irisTestDomain}`;
        this.proxyMicroserviceUrl = `https://proxy.link.${this.irisTestDomain}`;
        this.syncMicroserviceUrl = `https://sync.link.${this.irisTestDomain}`;
        this.mioAdminUrl = `https://admin.api.${this.irisTestDomain}`;
        this.autodeskClientId = '5IAGyHgAlM5GoJgrfuB7ZY9CZjlFpBqKD88gxfOVnmotqBZQ';
        this.parentDomain = 'iris.modelical.io';
        this.cookiesSubfix = 'IrisModelicalIo';
    }

    getPlatform = (): Platform => {
        return 'azure';
    };

    getAuth = (): any | undefined => {
        return this.auth;
    };

    getParentUrl = (): string | undefined => {
        return this.parentUrl;
    };

    getAdminMicroserviceUrl = (): string => {
        return this.adminMicroserviceUrl;
    };

    getProxyMicroserviceUrl = (): string => {
        return this.proxyMicroserviceUrl;
    };

    getSyncMicroserviceUrl = (): string => {
        return this.syncMicroserviceUrl;
    };

    getMioAdminUrl = (): string => {
        return this.mioAdminUrl;
    };

    getAutodeskClientId = (): string => {
        return this.autodeskClientId;
    };

    getTenant = (): Tenant => {
        return 'cellnex';
    };

    getParentDomain = (): string => {
        return this.parentDomain;
    };

    getCookiesSubfix = (): string => {
        return this.cookiesSubfix;
    };
}
