import { Setting } from '../model/mio-admin-entities';
import mioAdminApiAxios from './MioAdminAxios';

class MioAdminSettingApi {
    list = async (organizationCode: string, projectCode?: string, userEmail?: string): Promise<Setting[]> => {
        const response = await mioAdminApiAxios.get<Setting[]>('/settings/external', {
            params: { organizationCode, projectCode, userEmail },
        });
        return response.data;
    };
}

const mioAdminSettingApi: MioAdminSettingApi = new MioAdminSettingApi();
export default mioAdminSettingApi;
