import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import configuration from '../configs/IConfiguration';
import authService from '../security/IAuthService';

// url for mio admin api
export const mioAdminApiBaseURL = configuration.getMioAdminUrl();

/****** RESOURCES APIS ***/

// request interceptor
const axiosRequestInterceptor = async (
    config: AxiosRequestConfig,
): Promise<AxiosRequestConfig | Promise<AxiosRequestConfig>> => {
    let accessToken: string | undefined;
    try {
        accessToken = await authService.getAccessToken();
    } catch (error) {
        accessToken = undefined;
    }

    return {
        ...config,
        headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : null,
            ...config.headers,
        },
    };
};

// basic request interceptor
const basicAxiosRequestInterceptor = async (
    config: AxiosRequestConfig,
): Promise<AxiosRequestConfig | Promise<AxiosRequestConfig>> => {
    let accessToken: string | undefined;
    try {
        accessToken = await authService.getAccessToken();
    } catch (error) {
        accessToken = undefined;
    }

    return {
        ...config,
        headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : null,
            ...config.headers,
        },
    };
};

/*** ADMIN API ***/

// config
const mioAdminApiAxiosConfig: AxiosRequestConfig = {
    baseURL: mioAdminApiBaseURL,
};

// create axios http client
const mioAdminApiAxios: AxiosInstance = axios.create(mioAdminApiAxiosConfig);
mioAdminApiAxios.interceptors.request.use(axiosRequestInterceptor);
export default mioAdminApiAxios;

// create basic axios http client
const basicMioAdminApiAxiosInstance: AxiosInstance = axios.create(mioAdminApiAxiosConfig);
basicMioAdminApiAxiosInstance.interceptors.request.use(basicAxiosRequestInterceptor);
export const basicAdminApiAxios = basicMioAdminApiAxiosInstance;
