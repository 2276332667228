import { message } from 'antd';
import mioAdminSettingApi from '../api/MioAdminSettingApi';
import { AutodeskSetting, Setting, SharepointSetting } from '../model/mio-admin-entities';

class MioAdminSettingService {
    mioAdminSettings: Setting[] = [];

    /**
     * Initializes the mio admin setting service.
     */
    init = async (organizationCode: string) => {
        try {
            this.mioAdminSettings = await mioAdminSettingApi.list(organizationCode);
        } catch (error: any) {
            message.error('Internal error');
        }
    };

    /**
     * Retrieves the sharepoint setting if it exists
     * @returns a sharepoint setting or undefined
     */
    getSharepointSetting = (): SharepointSetting | undefined => {
        const sharepointSetting = this.mioAdminSettings
            .filter((setting) => setting.type === 'SHAREPOINT')
            .find(Boolean);
        let sharepointSettingValue;
        if (sharepointSetting) {
            sharepointSettingValue = sharepointSetting.value as SharepointSetting;
        }
        if (
            !!sharepointSettingValue &&
            !!sharepointSettingValue.clientId &&
            !!sharepointSettingValue.tenantId &&
            sharepointSettingValue.clientId.trim().length > 0 &&
            sharepointSettingValue.tenantId.trim().length > 0
        ) {
            return sharepointSettingValue;
        } else {
            return undefined;
        }
    };

    /**
     * Retrieves the autodesk setting if it exists
     * @returns a autodesk setting or undefined
     */
    getAutodeskSetting = (): AutodeskSetting | undefined => {
        const autodeskSetting = this.mioAdminSettings.filter((setting) => setting.type === 'AUTODESK').find(Boolean);
        let autodeskSettingValue;
        if (autodeskSetting) {
            autodeskSettingValue = autodeskSetting.value as AutodeskSetting;
        }
        if (
            !!autodeskSettingValue &&
            !!autodeskSettingValue.publicClientId &&
            autodeskSettingValue.publicClientId.trim().length > 0
        ) {
            return autodeskSettingValue;
        } else {
            return undefined;
        }
    };

    /**
     * Returns the settings of model path type
     * @returns model path settings
     */
    getModelPathSettings = (): Setting[] => {
        return this.mioAdminSettings.filter((setting) => setting.type === 'MODEL_PATH');
    };
}

const mioAdminSettingService: MioAdminSettingService = new MioAdminSettingService();
export default mioAdminSettingService;
